<template>
  <b-card no-body>
    <div class="card-header">
      <!-- Title & SubTitle -->
      <div>
        <b-card-title
          >Días Productivos</b-card-title
        >
        <b-card-sub-title>
          Selecciona el periodo a planificar
        </b-card-sub-title>
      </div>
    </div>

    <!-- Card Body  -->
    <b-card-body>
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col>
            <validation-provider #default="{ errors }" rules="required">
              <b-form-group
                label="Planification Start Date"
                label-for="planification_start_date"
              >
                <b-form-datepicker
                  id="planification_start_date"
                  placeholder="Seleccione fecha"
                  :state="errors.length > 0 ? false : null"
                  v-model="planification_start_date"
                  @input="planificationStartDateInput"
                  :min="planification_start_min_date"
                  :value-as-date="true"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col>
            <validation-provider #default="{ errors }" rules="required">
              <b-form-group
                label="Planification End Date"
                label-for="planification_end_date"
              >
                <b-form-datepicker
                  id="planification_end_date"
                  placeholder="Seleccione fecha"
                  :state="errors.length > 0 ? false : null"
                  v-model="planification_end_date"
                  @input="planificationEndDateInput"
                  :min="planification_end_min_date"
                  :max="planification_end_max_date"
                  :value-as-date="true"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>

      <!--
      <b-alert variant="primary" show>
        <div class="alert-body">
          <span
            >Tootsie roll lollipop lollipop icing. Wafer cookie danish macaroon.
            Liquorice fruitcake apple pie I love cupcake cupcake.</span
          >
        </div>
      </b-alert>
-->
      <ag-grid-vue
        style="width: 100%; height: 500px"
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef"
        @cellValueChanged="onCellValueChanged"
        :rowData="rowData"
      ></ag-grid-vue>
    </b-card-body>
  </b-card>
</template>

<script>


import
{
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BFormGroup,
  BFormDatepicker,
  BFormCheckboxGroup,
  BAvatar,
  VBPopover,
  BButton,
  BAlert
} from "bootstrap-vue";

import { AgGridVue } from "ag-grid-vue";

import CheckBoxCellRender from "@/views/brunacomponents/ag-grid-table/cell-renderers/CheckBoxCellRender.vue"

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import
{
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length, numeric, decimal
} from '@validations'


import axios from '@axios'

import useApiServices from '@/services/useApiServices.js';

export default {

  directives: {
    "b-popover": VBPopover,
  },

  props: {
    selectedLineas: {
      type: Array,
      required: true,
      default: () => []
    },
  },

  watch: {

    selectedLineas: {
      handler(newValue, oldValue){
        this.updateTable()
      },

    }
  },

  data()
  {

    console.log("dias_productivos_tabs: ", this.selectedLineas)

    let now = new Date()
    let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    let planificationStartMinDate = new Date(today)

    return {

      lineas: [],

      planification_start_date: null,
      //planification_start_min_date: planificationStartMinDate,
      planification_start_min_date: null,


      planification_end_date: null,
      planification_end_min_date: null,
      planification_end_max_date: null,


      columnDefs: [

      ],
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        sortable: false,
        resizable: false,
        filter: false,
        editable: true,
        suppressMovable: true,
        suppressMenu: true

      },

      rowData: null,




    };
  },


  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BCardTitle,
    BCardSubTitle,
    BAvatar,
    BButton,

    BFormDatepicker,
    AgGridVue,
    VBPopover,
    ValidationProvider,
    ValidationObserver,
    BAlert,

    checkBoxCellRender: CheckBoxCellRender

  },

  created()
  {
    this.createTable()

  },


  methods: {

    createTable()
    {

      this.getDataLineas()
      .then((response) =>
      {
        console.log(response.data)

        this.lineas = response.data


        let now = new Date() 

        this.planification_start_date = new Date(now.getTime())

        let initialEndDate = new Date(now.getTime())
        initialEndDate.setDate(initialEndDate.getDate() + 31)
        this.planification_end_date = initialEndDate


        this.planification_end_min_date = new Date(now.getTime())

        this.planification_end_max_date = initialEndDate

        this.updateTable()

      })



    },

    updateTable()
    {
      if (!this.planification_start_date || !this.planification_end_date)
      {
        return;
      }

      let holydays = {
        0: {
          1: true
        },
        3: {
          6: true,
          7: true,
          8: true,
          9: true
        },
        4: {
          1: true,
          21: true
        },
        5: {
          8: true,
          21: true,
          26: true
        },
        6: {
          16: true
        },
        7: {
          15: true
        },
        8: {
          4: true,
          18: true,
          19: true
        },
        9: {
          10: true,
          31: true
        },
        10: {
          1: true,

        },
        11: {
          8: true,
          25: true,

        }
      }

      let startDate = new Date(this.planification_start_date);
      let endDate = new Date(this.planification_end_date);

      let columnDefs = [
        {
          field: "name2",
          headerName: 'Línea',
          pinned: "left"
        }
      ];

      let meses = {};

      for (var i = new Date(startDate.getTime()); i <= endDate; i.setTime(i.getTime() + (24 * 60 * 60 * 1000)))
      {

        let day = i.getDay()
        let date = i.getDate()
        let month = i.getMonth()
        let year = i.getFullYear()
        let month_name = i.toLocaleString('default', { month: 'long' });

        if (meses[month + "_" + year] == undefined)
        {
          meses[month + "_" + year] = {
            headerName: month_name + " " + year,
            children: [{
              field: "empty " + month,
              columnGroupShow: 'closed',
              headerName: ""
            }],
          }

          columnDefs.push(meses[month + "_" + year])
        }

        let columnDef = {
          field: "dias_funcionamiento.dia_" + i.getTime() + ".status",
          //cellRenderer: "checkBoxCellRender",

          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: ['Activada', 'Desactivada', 'Mantenimiento'],
          },

          columnGroupShow: 'open',
          headerName: i.toLocaleDateString(undefined, { weekday: 'short', month: 'long', day: 'numeric' })
        }

        if (day == 0 || day == 6)
        {
          columnDef.headerClass = "ag-header-cell-weekends"
          columnDef.cellStyle = { 'background-color': '#ffcccb' }

        }

        if (holydays[month] !== undefined && holydays[month][date] === true)
        {
          columnDef.cellStyle = { 'background-color': '#ffcccb' }
        }



        meses[month + "_" + year]["children"].push(columnDef)


      }

      this.columnDefs = columnDefs

      let selectedLineasIds = this.selectedLineas.map(linea => linea.id)

      let rowData = [];

      this.lineas.forEach(linea =>
      {

        if(!selectedLineasIds.includes(linea.id)){
          return
        }

        let row = linea;

        row["name2"] = linea.meta_data.planta + " - " + linea.meta_data.nombre_linea
        row["planta"] = linea.meta_data.planta
        row["nombre_linea"] = linea.meta_data.nombre_linea

        row["dias_funcionamiento"] = {};


        let available_on_holidays = linea.meta_data.available_on_holidays
        let available_on_weekends = linea.meta_data.available_on_weekends

        let dia_desde_inicio = 0

        for (var i = new Date(startDate.getTime()); i <= endDate; i.setTime(i.getTime() + (24 * 60 * 60 * 1000)))
        {
          let day = i.getDay()
          let date = i.getDate()
          let month = i.getMonth()
          let year = i.getFullYear()

          let key = "dia_" + i.getTime()

          if (holydays[month] !== undefined && holydays[month][date] === true)
          {
            row["dias_funcionamiento"][key] = {
              dia_desde_inicio: dia_desde_inicio,
              day: day,
              month: month,
              year: year,
              date: date,
              status: available_on_holidays ? 'Activada' : 'Desactivada'
            };
          }
          else if (day == 0 || day == 6)
          {
            row["dias_funcionamiento"][key] = {
              dia_desde_inicio: dia_desde_inicio,
              day: day,
              month: month,
              year: year,
              date: date,
              status: available_on_weekends ? 'Activada' : 'Desactivada'
            };
          }
          else
          {
            row["dias_funcionamiento"][key] = {
              dia_desde_inicio: dia_desde_inicio,
              day: day,
              month: month,
              year: year,
              date: date,
              status: 'Activada'
            };
          }

          dia_desde_inicio++

        }

        rowData.push(row);

      });

      this.rowData = rowData


    },

    planificationStartDateInput(startDate)
    {
      console.log("planificationStartDateInput", {startDate})

      this.planification_end_min_date = startDate;

      let startDate0 = new Date(startDate)
      startDate0.setDate(startDate0.getDate() + 30);
      this.planification_end_max_date = startDate0
      this.planification_end_date = startDate0


      this.updateTable()

    },

    planificationEndDateInput(planificationEndDate)
    {

      this.updateTable()



    },


    getDataLineas()
    {
      return axios.request({
        method: "get",
        url: useApiServices.lineasIndex,
        headers: {
          Accept: "application/json",
        },
      })
    },




    firstDataRendered(params)
    {

      console.log("firstDataRendered")

      //this.gridColumnApi.autoSizeAllColumns();

    },

    onCellValueChanged(params)
    {

      console.log("onCellValueChanged");
      console.log(params);

      console.log(this.rowData);

      //this.$emit('change', this.gridApi, this.gridColumnApi, params);
    },

    onGridReady(params)
    {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;


    },


    validate()
    {
      console.log(this.rowData)

      return true
    },

    getRange()
    {
      return {'inicio': this.planification_start_date, 'fin': this.planification_end_date}
    }, 

    getData()
    {

      let rows = []

      this.rowData.forEach(row => {

        let dias_no_funcionamiento = []

        let dias_funcionamiento = row["dias_funcionamiento"] 

        let count = 0

        Object.keys(dias_funcionamiento).forEach(key => {

          let data = dias_funcionamiento[key]

          if(data.status != 'Activada')
          {
            dias_no_funcionamiento.push(data)
            count += 1
          }

        });

        let rowCopy = {
          name: row.name2,
          planta: row.planta,
          nombre_linea: row.nombre_linea,
          dias_no_funcionamiento: dias_no_funcionamiento
        }

        if(count > 0){
          rows.push(rowCopy)
        }


      })

      let data = {
        'inicio': this.planification_start_date, 
        'fin': this.planification_end_date,
        'dias_no_funcionamiento': rows
      }

      return data
    }




  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>


<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "@core/scss/vue/pages/ui-feather.scss";

//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

div.ag-watermark {
  display: none !important;
}

/*

.ag-pivot-off .ag-header-group-cell {
  font-size: 25px;
  color: #8a6d3b;
}

.ag-pivot-on .ag-header-group-cell {
  font-size: 25px;
  color: #8a6d3b;
}

.ag-cell-label-container {
  //Necessary to allow for text to grow vertically 
  height: 100%;
}

.ag-header-cell-label {
  //Necessary to allow for text to grow vertically 
  height: 100%;
  padding: 0 !important;
}

.ag-header-cell-label .ag-header-cell-text {
  //width: 30px;
  //transform: rotate(90deg);
  //margin-top: 50px;
  // Since we are rotating a span 
  display: inline-block;
}

.ag-floating-filter-body input {
  height: 49px;
}

.ag-floating-filter-button {
  margin-top: -49px;
}

.ag-floating-filter-button button {
  height: 49px;
}

.ag-floating-filter-body input {
  font-size: 15px;
  font-weight: bold;
}

.ag-pivot-off .ag-header-cell-label {
  color: #8a6d3b;
}

.ag-pivot-on .ag-header-cell-label {
  color: #1b6d85;
  font-weight: bold;
}
*/

.ag-header-cell-weekends {
  color: #cd5c5c !important;
}
</style>

<style lang="scss" scoped>
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

