<template>
  <div>
    <b-row>
      <b-col md="3" lg="3" v-for="stat in resume" :key="stat.id">
        <stat
          :variant="stat.variant"
          :icon="stat.icon"
          :name="stat.name"
          :value="stat.value"
          :unit="stat.unit"
        ></stat>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Stat from '../../../base/components/Stat.vue'


export default {
  props: {
    resume: {
      type: Array,
      default: [],
    },
  },
  
  components: {
    BRow,
    BCol,
    Stat,
  },

  methods: {

  },

  data() {
    return {
    }
  }
}
</script>