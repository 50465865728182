<template>
  <b-col>
    <validation-observer ref="simpleRules">
      <b-row v-for="(value, index) in values" :key="index">
        <!--
        <b-col cols="1">
            <span>#{{ index+1 }}</span>
        </b-col>
        -->
        <b-col>
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="metrics"
          >
            <b-form-tags
              v-model="values[index]['metrics']"
              size="lg"
              class="mb-2"
              add-on-change
              no-outer-focus
            >
              <template
                v-slot="{
                  tags,
                  inputAttrs,
                  inputHandlers,
                  disabled,
                  removeTag,
                }"
              >
                <ul
                  v-if="tags.length > 0"
                  class="list-inline d-inline-block mb-2"
                >
                  <li
                    v-for="tag in tags"
                    :key="tag"
                    class="list-inline-item"
                    style="margin-bottom: 0.5rem"
                  >
                    <b-form-tag
                      @remove="removeTag(tag)"
                      :title="tag"
                      :id="tag"
                      :disabled="disabled || !editable"
                      variant="info"
                      >{{ metricsById[tag].name }}</b-form-tag
                    >
                    <b-tooltip
                      :target="tag"
                      triggers="hover"
                      v-if="metricsById[tag].description.length > 0"
                    >
                      {{ metricsById[tag].description }}
                    </b-tooltip>
                  </li>
                </ul>
                <b-form-select
                  v-bind="inputAttrs"
                  v-on="inputHandlers"
                  :disabled="disabled || availableOptions().length === 0 || !editable"
                  :options="availableOptions()"
                  size="sm"
                >
                  <template #first>
                    <!-- This is required to prevent bugs with Safari -->
                    <option disabled value="">Choose a metric...</option>
                  </template>
                </b-form-select>
              </template>
            </b-form-tags>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="2">
          <validation-provider
            #default="{ errors }"
            rules="required|positive"
            name="weight"
          >
            <b-form-group label="Weight" label-for="weight">
              <b-form-input
                id="weight"
                v-model="values[index]['weight']"
                type="text"
                placeholder=""
                :state="errors.length > 0 ? false : null"
                size="sm"
                :disabled="!editable"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="2">
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="tipo"
          >
            <b-form-group label="Tipo" label-for="tipo">
              <b-form-select
                v-model="values[index]['tipo']"
                :options="type_options"
                size="sm"
                :disabled="!editable"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="3">
          <b-button-toolbar
            class="demo-inline-spacing"
            aria-label="Toolbar with button groups and input groups"
            v-if="editable"
          >
            <b-button-group size="sm">
              <b-button
                @click="(event) => upItem(index)"
                variant="outline-secondary"
                class="mt-0"
                size="sm"
              >
                <feather-icon icon="ArrowUpIcon" class="mr-50" />
                <span class="align-middle">Up</span>
              </b-button>
              <b-button
                @click="(event) => downItem(index)"
                variant="outline-secondary"
                class="mt-0"
                size="sm"
              >
                <feather-icon icon="ArrowDownIcon" class="mr-50" />
                <span class="align-middle">Down</span>
              </b-button>
              <b-button
                @click="(event) => deleteItem(index)"
                variant="outline-secondary"
                class="mt-0"
                size="sm"
              >
                <feather-icon icon="Trash2Icon" class="mr-50" />
                <span class="align-middle">Delete</span>
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
        <b-col cols="12">
          <span style="font-weight: bold; font-family: monospace">{{
            getResume(index)
          }}</span>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          

          <b-button-toolbar key-nav justify aria-label="" v-if="editable">

            <b-button-group size="sm">
              <b-button @click="addItem" variant="secondary">Add New</b-button>
            </b-button-group>

            <b-button-group size="sm">
              <b-button @click="saveChanges" :disabled="!has_changes" variant="outline-primary">Guardar Cambios</b-button>
              <b-button @click="addNewObjectiveFunction" variant="outline-primary">Crear Nueva Función Objetivo</b-button>
            </b-button-group>
           
          </b-button-toolbar>
        </b-col>
      </b-row>
    </validation-observer>

    <!--
    <div>
        <pre>{{ values }}</pre>
    </div>
-->
  </b-col>
</template>
  
  <script>
const axios = require("axios");

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import
{
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  numeric,
  decimal,
} from "@validations";


import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";

export default {

  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BCardTitle,
    BCardSubTitle,
    ValidationProvider,
    ValidationObserver,
    VBTooltip,
    VBPopover,



  },
  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  created()
  {
  },

  /*
  watch: {

    values: {
      handler(newValue, oldValue)
      {
        this.$emit('input', newValue)
      },
      deep: true

    }
  },
*/
  methods: {

    saveChanges()
    {
      this.$emit('saveChanges');
    },

    addNewObjectiveFunction()
    {
      this.$emit('addNewObjectiveFunction');
    },    



    validate()
    {
      return this.$refs.simpleRules.validate()
    },

    upItem(index)
    {
      if (index - 1 >= 0)
      {
        let rows = [this.values[index - 1], this.values[index]];
        this.values.splice(index - 1, 2, rows[1], rows[0]);

        this.$emit('change');
        this.has_changes = true
      }
    },

    downItem(index)
    {

      if (index + 1 < this.values.length)
      {
        let rows = [this.values[index], this.values[index + 1]];
        this.values.splice(index, 2, rows[1], rows[0]);

        this.$emit('change');
        this.has_changes = true

      }


    },

    addItem()
    {
      this.values.push({
        metrics: [],
        weight: 1.0,
        tipo: 'minimizar'
      })

      this.$emit('change');
      this.has_changes = true

    },

    deleteItem(index)
    {
      console.log("deleteItem", index)

      if (this.values.length > 1)
      {
        this.values.splice(index, 1)
        this.$emit('change');
        this.has_changes = true

      }

    },




    getResume(index)
    {

      let value = this.values[index]

      if (value.metrics.length > 0)
      {
        //return value.weight + " * (" + value.metrics.join(" + ") + ")"


        if (value.tipo == 'minimizar')
        {
          return "Minimizar(" + value.metrics.join(" + ") + ")"
        }

        if (value.tipo == 'maximizar')
        {
          return "Maximizar(" + value.metrics.join(" + ") + ")"
        }

      }

      return ""

    },



    availableOptions()
    {
      let selected = []

      this.values.forEach((value, index) =>
      {
        selected = selected.concat(value.metrics)
      })

      let filtered_metrics = this.metrics.filter(opt => selected.indexOf(opt.id) === -1)

      return filtered_metrics.map(metric => ({ value: metric.id, text: metric.name }))

    }




  },

  computed: {
    metricsById: function ()
    {

      let out = {}

      this.metrics.forEach(metric =>
      {
        out[metric.id] = metric
      })

      return out
    },

    values: {
      get()
      {
        return this.value
      },
      set(value)
      {
        this.$emit('input', value)
        this.has_changes = false
      }
    }

  },

  mounted()
  {

  },

  beforeDestroy()
  {
    //console.log("beforeDestroy 2");
  },

  data()
  {



    return {

      has_changes: true,

      type_options: [
        { value: null, text: 'Please select an option' },
        { value: 'minimizar', text: 'Minimizar' },
        { value: 'maximizar', text: 'Maximizar' },

      ],

      //values: this.value,


    };
  },

  props: {

    metrics: {
      type: Array,
      default: () => []
    },

    value: {
      type: Array,
      default: () => []
    },


    editable: {
      type: Boolean,
      default: () => true
    },


  },
};
  </script>
  