<template>
  <b-row>
    <b-col>
      <ag-grid-table
        ref="demandaRef"
        :configUrl="historicoOrdersConfig"
        :dataUrl="historicoOrdersData"
        @selectionChanged="onSelectionChanged"
        rowSelection="multiple"
        @gridReady="gridReady"
        @filterChanged="filterChanged"
        :getRowNodeId="getRowNodeId"
        :paginationPageSize="500"
        :cacheBlockSize="500"
        :debug="true"
      >
        <template v-slot:actions>

            <b-button-toolbar
            aria-label="Toolbar with button groups and input groups"
            justify
          >
            <b-button-group style="margin-bottom: 1rem">
              <b-button
                size="sm"
                @click="selectAll()"
                variant="primary"
                >{{ $t('Select All') }}</b-button
              >
              <b-button
                size="sm"
                @click="clearSelection()"
                variant="secondary"
                >{{ $t('Clear Selection') }}</b-button
              >


            </b-button-group>

            <div>
                Registros seleccionados: {{ selected_demanda.length }}
            </div>

          </b-button-toolbar>

        </template>
      </ag-grid-table>
    </b-col>
  </b-row>
</template>
   
<script>
import { BRow, BCol, BFormGroup, BButton, BFormSelect } from "bootstrap-vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import useApiServices from "@/services/useApiServices.js";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import Button from '../../../../vuexy/components/button/Button.vue';

export default {
  props: {
  },
  components: {
    BRow,
    BCol,
    BrunaForm,
    AgGridTable,
    Button
  },


  methods: {

    filterChanged(gridApi, gridColumnApi, params)
    {
        console.log("filtereChanged", {params})

    },

    gridReady(gridApi, gridColumnApi, params)
    {
      this.gridApi = gridApi
      console.log("gridReady", {params})
    },

    clearSelection()
    {
        this.gridApi.deselectAll()
    },

    selectAll()
    {
      console.log("select all")

      this.gridApi.forEachNode((rowNode,  index) => {
        console.log(rowNode)
        rowNode.setSelected(true)
      })
    },

    onSelectionChanged(rows)
    {
      console.log("onSelected", rows)

      this.selected_demanda = rows

      //this.contratos_excluidos = rows
      //this.contratos_excluidos_names = rows.map(row => row.meta_data.nueva_demanda.sku.sku)
    },



    getData()
    {
      return this.selected_demanda.map(item => item.id);
    },


    validate()
    {
      return this.selected_demanda.length > 0
    }


  },



  data()
  {
    return {

      gridApi: null,

      selected_demanda: [],

      historicoOrdersConfig: useApiServices.nuevaPlanificacion.orders.config,
      historicoOrdersData: useApiServices.nuevaPlanificacion.orders.data,


      getRowNodeId: (params) =>
      {
        return params.id;
      },

    };
  },
};
   </script>
   